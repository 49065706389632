import { gql, useMutation } from '@apollo/client'

const UPLOAD_ATTACHMENT = gql`
  mutation uploadAttachment($file: Upload!, $principalId: ID!, $attachmentType: String!) {
    createAttachment(input: { principalId: $principalId, file: $file, attachmentType: $attachmentType }) {
      id
      filename
      originalFilename
      fileExtension
      url
    }
  }
`

const useUploadAttachment = options => {
  const [
    upload,
    { data: { createAttachment: attachment } = {}, error, loading },
  ] = useMutation(UPLOAD_ATTACHMENT, { ...options })

  return [upload, { attachment, error, loading }]
}
export default useUploadAttachment
